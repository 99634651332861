import React from "react";
import Routes from "./Routes";
import Footer from './components/Footer';
import Navbar from './components/Navbar'
import useScript from "react-script-hook";
import {Helmet} from 'react-helmet'
import { SPLOADER, SPFORMID } from "./constants";

const App = (props) => {
  useScript({ src: SPLOADER })

  return (
    <>
    <Helmet>
      <title>6 C'S Copiers and Supplies</title>
      <meta
        name="description"
        content="Wholesale distributor of new and pre-owned copiers and OEM supplies. Family owned with over
        40 years of experience in the retail and wholesale markets."
        data-react-helmet="true"
      /> 
    </Helmet>
      <div className="bg-light-gray">
        <div className="bg-near-white center w-75-l w-90-m mt3">
          <Navbar />
          <div className="pt5">
            <Routes />
          </div>
          <script src={SPLOADER} sp-form-id={SPFORMID}></script>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App