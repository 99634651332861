import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Copiers from "./containers/inventory/Copiers";
import Supplies from "./containers/inventory/Supplies";
// import Contact from "./containers/Contact";


import { useEffect } from "react";
import { useLocation } from "react-router-dom";
export default function Routes() {
  return (<>
    <ScrollToTop/>
    <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/inventory/copiers" exact component={Copiers} />
        <Route path="/inventory/supplies" exact component={Supplies} />
        {/* <Route path="/contact" exact component={Contact} /> */}
        { /* Finally, catch all unmatched routes */ }
        <Route component={NotFound} />
    </Switch></>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}