import React, { useState, useCallback } from "react";
import {Carousel} from "react-responsive-carousel"
import Banner from "../components/banner/Banner"
import useSWR from 'swr'
import fetchFile from '../utilities/Fetcher'
import { DefaultColumnFilter } from '../components/table/generics/Filters'
import Button from "react-bootstrap/esm/Button";
import Select from 'react-select';
import {
  Link, useLocation
} from "react-router-dom";
import { INVENTORY_URL, QUOTES, CAROUSEL_INFO, BANNER_INFO } from "../constants";
import { useQueryState } from "react-router-use-location-state";

export default function Home() {
  // TODO: add the error object here
  const { data } = useSWR(INVENTORY_URL, fetchFile)
  const [filterValue, setFilterValue] = useState({})
  const [queryString, setQueryString] = useQueryState("make", []);
  const location = useLocation()

  const getMakes = useCallback((key) => { 
      const options = new Set()
      data[1].forEach(row => {
          options.add(row[key])
      })

      return  [...options.values()].map(x => ({label:x,value:x}))
    }, [data]
  )


  // <Select
  // className="w-100"
  // isMulti
  // options={options}
  // value={queryString.map(qs =>  options.find(option => { console.log("option ", option); return option.value === qs}))}
  // onChange={e => {
  //     setFilter(e?.map(v => v.value) || undefined)
  //     setQueryString(e?.map(v => v.value) || undefined)
  // }}

  const getFilters = useCallback(() => {
    return data && data[0].map(column => {
        if (column.Header === "Make" || column.Header === "Model") {
            return <div key={`${column.Header}.filter`} className="w-75">
                <span className="f6 b db">{column.Header}</span>
                {
                  column.Header==='Make' ? 
                  <Select
                    className="black"
                    options={getMakes("Make")}
                    value={queryString.map(qs => getMakes("Make").find(option => option.value === qs))}
                    isClearable
                    isMulti
                    menuPortalTarget={document.body} 
                    onChange={e => setQueryString(e?.map(v => v.value) || undefined)}
                  >
                  </Select> :
                      <DefaultColumnFilter 
                      column={{
                        filterValue: filterValue['Model'],
                        id: 'model',
                        preFilteredRows: data[1].filter(x => queryString && queryString.length ? queryString.includes(x['Make']) : true),
                        setFilter: value => setFilterValue({...filterValue, Model: value})
                      }}/>
                }
            </div>
        } return undefined
      }
    )
  }, [data, filterValue, setFilterValue, getMakes, queryString, setQueryString])

  return (
    <>
      <Carousel className={"ma2 shadow-1"} interval={7000} transitionTime={1000} infiniteLoop={true} autoPlay={true} showArrows={true} showThumbs={false} showStatus={false}>
        {CAROUSEL_INFO.map(info => 
          <div 
            key={info.headerMessage.replace(' ','_')}
            className={`h-100 ${info.backgroundColor} flex flex-column justify-center`}
          >
            <div className={`pv4 ph2 tc ${info.textColor}`}>
              <h4>{info.headerMessage}</h4>
              <p className="fw6">{info.textMessage}</p>
            </div>
          </div>
        )}
      </Carousel>
      {BANNER_INFO.map((banner, idx) => <Banner 
          key={banner.header.replace(' ','_')}
          header={banner.header}
          content={banner.content}
          buttonText={banner.buttonText}
          buttonLink={banner.buttonLink}
          bgColor={banner.bgColor ? banner.bgColor : idx % 2 === 0 ? 'light-gray' : ''}
          image={banner.image}
          align={banner.align ? banner.align : idx % 2 === 0 ? 'right' : ''}
          // weird, only needed for 1, but it works
          render={banner.render && <>
              <div className={`
                  border shadow-5 bg-light-gray  
                  flex 
                  flex-wrap
                  justify-around
                  black
                  tl
                  mt2`}
              >
                  {data && getFilters()}
                  <div className="w-100 pv2 tc mb-auto mt-auto">
                      <Link to={{ pathname: "/inventory/copiers", search: location.search }}
                        onClick={() => {
                          window.gtag("event", "button_click", 
                          {
                              "filters": location.search,
                              "link_text": `View Inventory`,
                          })
                     }}>
                          <Button>View Inventory</Button>
                      </Link>
                  </div>
              </div>
          </>}
        />
      )}
        
      <Carousel className={"ma2 shadow-1"} interval={7000} transitionTime={1000} infiniteLoop={true} autoPlay={true} showArrows={true} showThumbs={false} showStatus={false}>
        {QUOTES.map(quote => 
          <div 
            key={quote.message.replace(' ','_')}
            className={`h-100 flex flex-column justify-center ${quote.backgroundColor}`}
          >
            <div className={`pv4 ph2 tc ${quote.textColor}`}>
              <h4>{quote.message}</h4>
              <span>{quote.author}</span>
            </div>
          </div>)}
      </Carousel>
    </>
  );
}