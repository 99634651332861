import React, { useState } from 'react'
import Nav from 'react-bootstrap/Nav' 
import BsNavbar from 'react-bootstrap/Navbar'
import { Link, useLocation } from 'react-router-dom'

const Navbar = () => {
    const [expanded, setExpanded] = useState(false);

    const location = useLocation()
    return (
        <BsNavbar bg="dark" variant="dark" expanded={expanded} expand="sm" className="fixed-top fw6 f5">
            <Link onClick={() => setExpanded(false)} className="navbar-brand mr0 pa0 w-third-l" to="/">
                <img src={require('../assets/6CS_Logo_Design.png')} width="200" style={{minWidth: '200px'}} className="d-inline-block align-top" alt="6 C'S Copiers and Supplies Logo"/>
            </Link>
            <BsNavbar.Collapse id="responsive-navbar-nav" className="w-100 ">
                <Nav className="w-third-l center-ns pr3-m">
                    {/* Consider making ^this^ flex-column and justify-around-m [need to handle padding bottom brought in from nav-link though] */}
                    <Link onClick={() => setExpanded(false)} className="nav-link f4-l f5-m" to={`/inventory/copiers${location.search}`}>
                        Copiers
                    </Link>
                    <Link onClick={() => setExpanded(false)} className="nav-link f4-l f5-m" to="/inventory/supplies">
                        Supplies
                    </Link>
                </Nav>
                <div className="justify-end-l tr-ns">
                    <div className="flex flex-column">
                        <a className="white f6-m" href="mailto:contact@copiersandsupplies.com" target="_blank" rel="noopener noreferrer" onClick={() => setExpanded(false)} >
                            contact@copiersandsupplies.com
                        </a>
                        <a className="white f6-m" href="tel:+6309801015" target="_blank" rel="noopener noreferrer" onClick={() => setExpanded(false)}>  
                            (630) 980-1015
                        </a>
                    </div>
                </div>               
            </BsNavbar.Collapse>
            <BsNavbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")}  className="pa0" aria-controls="responsive-navbar-nav" />
        </BsNavbar>
    )
}

export default Navbar