const xlsx = require('xlsx')

const fetchFile = async (resource) => {
    /* set up an async GET request */
    const data = new Promise((resolve, reject) => {
        var req = new XMLHttpRequest();
        req.open("GET", resource, true);
        req.responseType = "arraybuffer";

        req.onload = () => {
            /* parse the data when it is received */
            if (req.status !== 200) {
                return reject("Unable to load spreadsheet")
            }

            var data = new Uint8Array(req.response);
            const workbook = xlsx.read(data, {type:"array"});
            const lastUpdated = workbook.Props.ModifiedDate || workbook.Props.CreatedDate

            const worksheet = workbook.Sheets[workbook.SheetNames[0]]
            
            var fileHeaders = [];
            var fileData = [];

            for(let z in worksheet) {
                if(z[0] === '!') continue;
                //parse out the column, row, and value
                var tt = 0;
                for (var i = 0; i < z.length; i++) {
                    if (!isNaN(z[i])) {
                        tt = i;
                        break;
                    }
                };

                var col = z.substring(0,tt);
                var row = parseInt(z.substring(tt));
                var value = worksheet[z].v;

                if(row === 1 && value) {
                    fileHeaders.push({Header: value, accessor: value})
                    continue;
                }

                // INVENTORY SPECIFIC MUTATIONS on row data
                if(!fileData[row]) 
                    fileData[row]={};
                
                fileData[row][fileHeaders[col.charCodeAt(0) - 65].Header] =             
                    col === "A" ? // Make
                        value.toUpperCase() : 
                    col === "C" ? // meter
                        value : // value.replace(/,/g, '') :
                    col === "D" ? // Accessories
                        value.replace(/,/g, '') :
                    value;
            }
            //drop those first two rows which are empty
            fileData.shift();
            fileData.shift();
            return resolve( [fileHeaders, fileData, lastUpdated])
        };
        req.send();
    })
    return await data
}

export default fetchFile