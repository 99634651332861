import React, { useCallback, useEffect } from "react";
import {
    useLocation
  } from "react-router-dom";
import { useTable, useSortBy, usePagination, useFilters, useGlobalFilter, useRowSelect } from 'react-table'
import Pagination from './Pagination'
import { GlobalFilter, SelectColumnFilter, DefaultColumnFilter } from './generics/Filters'
import { Button } from "react-bootstrap";
import XLSX from 'xlsx'
import { useExportData } from 'react-table-plugins'

const getColumnWidth = (rows, accessor, headerText) => {
    const maxWidth = 400
    const magicSpacing = 10
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    )

    return Math.min(maxWidth, cellLength * magicSpacing)
}


const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef
  
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
  
      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

function getExportFileBlob({ columns, data }) {
    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
            obj[col] = row[index];
        });
        return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "inventory");
    XLSX.writeFile(wb, `6CS_Copiers.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }

const InventoryTable = ({headers, data: objectifiedRowData, lastUpdated, initialSearch, updateTable}) => {
    const location = useLocation()
    
    const defaultColumn = React.useMemo(
        () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
        }),
        []
    )

    // set specific filters
    // Meter
    // headers[2].Filter = NumberRangeColumnFilter
    // headers[2].filter = 'between'

    // Make
    headers[0].Filter = SelectColumnFilter
    headers[0].filter = 'includesSome'

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,

        // Pagination
        page, // AKA ROWS
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        

        // Filtering
        preGlobalFilteredRows,
        setGlobalFilter,
        setFilter,
        rowsById,
        exportData,
        state: { pageIndex, pageSize, globalFilter, filters, selectedRowIds },
    } = useTable(
        {
            columns: React.useMemo(() => headers, [headers]),
            data: React.useMemo(() => objectifiedRowData, [objectifiedRowData]),//objectifiedRowData.filter(rd => rd[headers[0].Header].toUpperCase() === selectedManufacturer || selectedManufacturer === "All",[])),
            defaultColumn,
            initialState:{
                pageSize: 20,
                filters: [
                    {
                        id: 'Make',  value: (initialSearch && initialSearch.make && (!Array.isArray(initialSearch.make) ? [initialSearch.make] : initialSearch.make)) || [''],
                    },
                    {
                        id: 'Model', value: (initialSearch && initialSearch.model) || ''
                    },
                ],
                globalFilter: (initialSearch && initialSearch.query) || ""
            },
            getExportFileBlob
        },
        hooks => {
          hooks.visibleColumns.push(columns => [
            // Let's make a column for selection
            {
              id: 'selection',
            //   // The header can use the table's getToggleAllRowsSelectedProps method
            //   // to render a checkbox
            //   Header: ({ getToggleAllRowsSelectedProps }) => (
            //     <div>
            //       <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            //     </div>
            //   ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ])
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        useExportData
    )

    useEffect(
        () => {
            // reset filters on data refresh
            filters.forEach(filter => setFilter(filter.id, filter.value))
            setGlobalFilter(globalFilter)
        }, 
    // eslint-disable-next-line
    [objectifiedRowData])

    useEffect(() => {
        function setupSpForm() {
            var refreshId = setInterval(() => {
                const [spForm] = document.getElementsByClassName('sp-form-outer')
                if(spForm) {
                  clearInterval(refreshId);
                  const newLoc = document.getElementById('IWANTTHISTOWORK')
                  newLoc.append(spForm)
                }
            }, 100)
        } 

        setupSpForm()

        return function cleanUp() {
            const footer = document.getElementsByTagName('footer')[0]
            const [spForm] = document.getElementsByClassName('sp-form-outer')
            footer.prepend(spForm)
        }
    }, [])

    // This is dead code around keeping the selected data through passive data refetches.
    // With using the Refresh Data, I have removed the passive refreshes
    // useEffect(
    //     () => {
    //         // reset filters on data refresh
    //         filters.forEach(filter => setFilter(filter.id, filter.value))
    //         setGlobalFilter(globalFilter)
    //         //console.log(rowsById)
    //         setTimeout(() => {
    //             Object.keys(selectedRowIds).forEach(id => {
    //                 console.log(id, rowsById[id])
    //                 rowsById[id].getToggleRowSelectedProps().onChange({target:{checked:true}})
    //             })
    //         }, 100)
    // }, 
    const buildInventoryEmail = useCallback(() => {
        let body = "\n\n------<<Type your message for Ed C. of 6 C'S above this line.>>------\n\nInventory of interest:\n"
        Object.keys(selectedRowIds).forEach(id => {
            if (!rowsById[id]) {
                return;
            }
            Object.keys(rowsById[id].values).forEach(header => {
                const value = rowsById[id].values[header]
                if(value) 
                    body+=`${header}: ${value} || `
            })
            body+="\n\n"
        })
        return `mailto:sales@copiersandsupplies.com?subject=Interest%20in%20the%20following%20copiers&body=` + encodeURI(body)
    }, [selectedRowIds, rowsById]) 

    return (
        <div className="pt3">
            <div className="flex justify-end pb2">
                <p className="f6 mt-auto mr2 mb-0">
                    Inventory last updated on {lastUpdated && lastUpdated.toString()}
                </p>
                <Button className="f7" onClick={() => { 
                    // setGlobalFilter('')
                    // setAllFilters([]); 
                    updateTable();
                    window.gtag("event", "button_click", { "link_text": `Refresh Table`})
                }}>Refresh Table</Button>
            </div>
            <div className="flex-l">
                {/* FILTERS */}
                <div className="w-third-l mb4 mb0-ns mr2-ns mb4-m"> 
                    <div className="ph2 pa1 bg-white shadow-1">
                        <span className="f3 fw6">Filters</span>
                        <span className="f6 b db mb2">Inventory Search:{' '}</span>
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                
                        {/* COLUMN Filtering */}
                        {headerGroups.map(headerGroup => (
                            headerGroup.headers.map(column => {
                                if (column.Header === "Make" || column.Header === "Model") {
                                    return <div key={`${column.Header}.filter`} className="pb2">
                                        <span className="f6 b db">{column.Header}</span>
                                        {column.canFilter ? column.render('Filter') : null}
                                    </div>
                                } return null
                            }
                            )
                        ))}

                        <Pagination
                            className="pt3"
                            gotoPage={gotoPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            pageCount={pageCount}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageOptions={pageOptions}
                            pageIndex={pageIndex}
                            setPageSize={setPageSize}
                            pageSize={pageSize}
                        />
                        <div className="mv2 flex">
                            <Button
                                onClick={() => {
                                    window.gtag("event", "file_download", 
                                    {
                                        "filters": location.search,
                                        "link_text": "Download Filtered Inventory",
                                        "file_name": "CopierInventory.xlsx"
                                    })
                                    exportData("xlsx", false)
                                }}
                                className="center"
                            >
                                Download Filtered Inventory
                            </Button>
                        </div>
                    </div>
                    <div className="mv4 ph2 tc flex flex-wrap justify-around">
                        Looking for a quote? Select some of the items in the table and send us an email!
                        <a href={buildInventoryEmail()} onClick={() => {
                             window.gtag("event", "send_email", 
                             {
                                 "filters": location.search,
                                 "link_text": `Contact us about ${
                                     Object.keys(selectedRowIds).filter(id => !!rowsById[id]).length
                                 } copiers`,
                             })
                        }} target="_blank" rel="noopener noreferrer"> 
                            <Button className="btn-secondary">
                                Contact us about {
                                    Object.keys(selectedRowIds).filter(id => !!rowsById[id]).length
                                } copiers
                            </Button>
                        </a>
                    </div>
                    {/* neesd to go here */}
                    <div id="IWANTTHISTOWORK"></div>
                </div>

                <div className="w-100 pa1 bg-white shadow-1 overflow-x-scroll">
                    <table 
                        className="f6 w-100 ph2"
                        {...getTableProps()}
                    >
                        <thead>
                            {headerGroups.map(headerGroup => (
                            <tr 
                                className=""
                                {...headerGroup.getHeaderGroupProps()}
                            >
                                {headerGroup.headers.map((column,idx) => (
                                    <th 
                                        className="fw6 tl pb3 pl1 pr3 bg-white br bb b--purple"
                                        width={idx === 0 ? 1 : getColumnWidth(page, column.id, column.id)}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                    >
                                        {column.render('Header')}
                                        
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </th>
                                ))}
                            </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map(
                            (row, i) => {
                                prepareRow(row);
                                return (
                                <tr 
                                    className="stripe-dark"
                                    {...row.getRowProps()}
                                >
                                    {row.cells.map(cell => {
                                        return (
                                            <td 
                                                className="pl1 pr3" {...cell.getCellProps()}
                                            >
                                                {cell.render('Cell')}
                                            </td> 
                                        )
                                    })}
                                </tr>
                                )}
                            )}
                        </tbody>
                    </table>
                </div>            
            </div>
            <div>
                <span className="fr">Showing {page.length} of ~{pageCount * pageSize}{' '}results</span>
                <Pagination
                    className="pt3"
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    pageCount={pageCount}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageIndex={pageIndex}
                    setPageSize={setPageSize}
                    pageSize={pageSize}
                    pageSizeDropdown={false}
                />
            </div>
        </div>
    )
}

export default InventoryTable