import React from 'react'
import Select from 'react-select';

const Pagination = (props) => {
    const {
        gotoPage, previousPage, nextPage, pageCount, 
        //canPreviousPage, 
        //canNextPage, 
        pageOptions, pageIndex, setPageSize, 
        //pageSize,
        //className
        pageSizeDropdown=true
    } = props

    return (
        <>
            {/* Pagination */}
            {pageSizeDropdown && <div  className="pb2">
                <span className="f6 b db">Page Size</span>
                <Select
                    placeholder="20"
                    options={[10, 20, 30, 40, 50].map(x => {return {label: x, value:x}})}
                    onChange={e => {
                        setPageSize(e.value)
                    }}
                >
                </Select>
            </div>}

            <nav>
                <div className="flex flex-wrap justify-end w-100">
                    <ul className="pagination mb0">
                        <li className="page-item">
                            <p onClick={() => gotoPage(0)} className="page-link" tabIndex="-1">{'<<'}</p>
                        </li>
                        <li onClick={() => previousPage()} className="page-item">
                            <p className="page-link">{'<'}</p>
                        </li>
                        <li onClick={() => nextPage()} className="page-item">
                            <p className="page-link">{'>'}</p>
                        </li>
                        <li className="page-item">
                            <p onClick={() => gotoPage(pageCount - 1)} className="page-link">{'>>'}</p>
                        </li>
                    </ul>
                    <div className="dib">
                        <span className="pl2 f6 b">Page</span>
                        <span className="f6 db ph2">{pageIndex + 1} of {pageOptions.length}</span>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Pagination