import React from 'react'
import Select from 'react-select';
import { useQueryState } from 'react-router-use-location-state'

// Define a default UI for filtering
export function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [queryString, setQueryString] = useQueryState("query", globalFilter || "");

    return (
        <div>
            <input
                className="input-reset ba b--black-20 pa2 mb2 db w-100"
                value={queryString || ''}
                onChange={e => {
                    setGlobalFilter(e.target.value || undefined)
                    setQueryString(e.target.value || "")
                }}
                placeholder={`${count} records...`}
            />
        </div>
    )
}

// Define a default UI for filtering
export function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter, id },
}) {
    const [queryString, setQueryString] = useQueryState(id.toLowerCase(), filterValue || '');
    const count = preFilteredRows.length
    return (
        <input
            className="input-reset ba b--black-20 pa2 db w-100"
            value={queryString || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                setQueryString(e.target.value || '')
            }}
            placeholder={`Search ${count} records...`}
        />
    )
}


// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const [queryString, setQueryString] = useQueryState(id.toLowerCase(), filterValue || []);

    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })

        return [...options.values()].map(x=>{return {value:x,label:x}})
    }, [id, preFilteredRows])
    // Render a multi-select box
    return (
        <Select
            className="w-100"
            isMulti
            options={options}
            value={queryString.map(qs =>  options.find(option => option.value === qs))}
            onChange={e => {
                setFilter(e?.map(v => v.value) || undefined)
                setQueryString(e?.map(v => v.value).length ? e?.map(v => v.value) : '')
            }}
        >
        </Select>
    )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div className="flex"
        >
            <input
                className="input-reset ba b--black-20 pa2 mr1 db w-100"
                value={filterValue[0] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                }}
                placeholder={`Min (${min})`}
            />
            <input
                className="input-reset ba b--black-20 pa2 mr1 db w-100"
                value={filterValue[1] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
                }}
                placeholder={`Max (${max})`}
            />
        </div>
    )
}