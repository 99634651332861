import React from "react";

class Footer extends React.Component {
    render() {
        return (
            <footer className="tc pv3 ph3 bg-black-05 mid-gray">
                <div>
                    <p className="f4 db mb1 mt0">Contact Us</p>
                    <p className="ma0">1271 Humbracht Circle Bartlett, IL 60103</p>
                    <a href="mailto:contact@copiersandsupplies.com" target="_top">
                        <p className="ma0">contact@copiersandsupplies.com</p>
                    </a>
                    <p className="ma0"></p>
                    <a href="tel:+6309801015">(630) 980-1015</a>
                </div>
                <div className="f7">Icons by <a href="https://freeicons.io/profile/6195">Lima Studio</a> and <a href="https://freeicons.io/profile/6156">Reda</a> on <a href="https://freeicons.io">freeicons.io</a></div>
                <div className="ph5-m ph6-l">
                    <small className="f7 pt2 db tc">© {new Date().getFullYear()} <b className="ttu">6 C'S Copiers and Supplies Inc</b>., All Rights Reserved</small>
                    {/* <div className="tc mt3">
                        <Link to="/about" title="about" className="f6 dib ph2 link mid-gray dim">About Us</Link>
                        <a href="/terms/"    title="Terms" className="f6 dib ph2 link mid-gray dim">Terms of Use</a>
                        <a href="/privacy/"  title="Privacy" className="f6 dib ph2 link mid-gray dim">Privacy</a>
                    </div> */}
                </div> 
            </footer>
        )
    }
}

export default Footer