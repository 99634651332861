import React from "react";
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

const Banner = ({header, content, buttonText, buttonLink, align, bgColor, image, render}) => {
  const alignStyle = `${align === "right" ? "pr4-l ml-auto-l mr-0-l" : "pl4-l"}`

  return  (
    <div className={`
            h5-l border ma4 shadow-5 bg-${bgColor} overflow-auto 
            flex 
            ${align==='right' ? 'flex-wrap' : 'flex-row-reverse-l flex-wrap'} 
            justify-between
            pb2
            overflow-hidden
            `}
    >
        {/* image */}
        <div 
            style={{ 
                backgroundImage: `url(${image})` ,
                backgroundPosition: 'center',
                backgroundRepeat:'no-repeat',
                backgroundSize:'250px 250px',
                minWidth: "250px",
                minHeight: "250px",
                maxWidth: "400px",
                maxHeight: "400px"
            }}
            className="rounded center m0-ns w-50-l w-50-m" 
            alt="Copier Right">
        </div>

        {/* Content */}
        <div className={`tc center tl-l w-50-l mt-auto mb-auto pa2 ${alignStyle}`}>
            <h2 className="b f3">{header}</h2>
            {render || <p className="">{content}</p>}
            {buttonText && 
              <Link 
                to={buttonLink}
                onClick={() => {
                  window.gtag("event", "button_click", 
                  {
                      "link_text": `${buttonText}`,
                  })
                }}>
                <Button variant={"info"}>{buttonText}</Button>
              </Link>}  
        </div> 

    </div>
  )
}

export default Banner