import React from 'react'

export const INVENTORY_URL = "https://copiersandsupplies-inventory.s3.us-east-2.amazonaws.com/Inventory.xlsx"
export const SPLOADER = "//web.webformscr.com/apps/fc3/build/loader.js"
export const SPFORMID = "5605706cc636997f3d479dcf96ae552544cfd896901edde1456b6df5f5dc11ae"
        //"I remember back in the 90s when I would have Ed come over to service my machines, although I miss having his handy work, the machines he sells me today much better fit what my customers throw at them!" - Ron S.',
export const QUOTES = [
    {
        message: '"I have done business with Ed for many years. He always gets me my equipment or parts in a timely manner and at a fair price. I would definitely recommend 6 C\'S Copiers"',
        author: "- First Choice Copier - South St. Paul, MN",
        backgroundColor: 'bg-blue',
        textColor: 'white'
    },
    {
        message: `"I have been doing business with Ed at 6 C'S copiers for many years and can highly recommend his company. He treats his customers with honesty and integrity, and will go out of his way to satisfy their needs for a wide range of office equipment. He is also a great source for OEM parts and supplies."`,
        author: '- Expert Copier Service Inc',
        backgroundColor: 'bg-gray',
        textColor: 'white'
    },
    {
        message: '"The folks at 6 C\'S have always worked very hard to serve our requests for equipment and support. We are confident that our customers will then receive the best value from us."',
        //"Ed has been my goto for acquiring machines the last 8 years. He puts his customers first and always promotes the machines he thinks will best match my needs, rather than those that are more expensive." - Jane F.',
        author: "- Frykman Technologies",
        backgroundColor: 'bg-light-blue',
        textColor: ''
    }
]

export const CAROUSEL_INFO = [
    {
        headerMessage: "Wholesale Distributor of Copiers and Supplies",
        textMessage: "OEM supplies & parts",
        backgroundColor: "bg-blue",
        textColor: "white"
    },
    {
        headerMessage: "All the Manufacturers you know and love",
        textMessage: "Minolta - Bizhub - Copystar - Kyocera - Toshiba and More!",
        backgroundColor: "bg-light-blue",
        textColor: "black"
    },
    {
        headerMessage: "New and pre-owned office equipment",
        textMessage: "OEM supplies & parts",
        backgroundColor: "bg-gray",
        textColor: "white"
    },
    {
        headerMessage: "Enjoy the the benefits of pre-owned office equipment",
        textMessage: "Get the same quality and value you expect, but at used equipment prices.",
        backgroundColor: "bg-washed-blue",
        textColor: "black"
    }
]

export const BANNER_INFO = [
    {
        header: "Pre-owned Office Equipment",
        content: 'Get the same quality and value you expect, but at used equipment prices. Inventories updated daily, so check back often.',
        buttonText: "View Copier Inventory",
        buttonLink: "/inventory/copiers",
        image: require('./assets/moneyIcon.png')
    },
    {
        header: "Inventory Search",
        render: true,
        image: require('./assets/magnifyingGlass.png')
    },
    {
        header: "Want to get in touch?",
        content: <div className="flex flex-column">
            Looking for prices or have a question about our inventory? Drop us a line!
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="blue mr2 bi bi-envelope" viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                </svg> 
                <a className="color-inherit pointer" href="mailto:sales@copiersandsupplies.com" target="_top">sales@copiersandsupplies.com  </a> 
            </div>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="blue mr2 bi bi-phone-vibrate" viewBox="0 0 16 16">
                    <path d="M10 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4zM6 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H6z"/>
                    <path d="M8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM1.599 4.058a.5.5 0 0 1 .208.676A6.967 6.967 0 0 0 1 8c0 1.18.292 2.292.807 3.266a.5.5 0 0 1-.884.468A7.968 7.968 0 0 1 0 8c0-1.347.334-2.619.923-3.734a.5.5 0 0 1 .676-.208zm12.802 0a.5.5 0 0 1 .676.208A7.967 7.967 0 0 1 16 8a7.967 7.967 0 0 1-.923 3.734.5.5 0 0 1-.884-.468A6.967 6.967 0 0 0 15 8c0-1.18-.292-2.292-.807-3.266a.5.5 0 0 1 .208-.676zM3.057 5.534a.5.5 0 0 1 .284.648A4.986 4.986 0 0 0 3 8c0 .642.12 1.255.34 1.818a.5.5 0 1 1-.93.364A5.986 5.986 0 0 1 2 8c0-.769.145-1.505.41-2.182a.5.5 0 0 1 .647-.284zm9.886 0a.5.5 0 0 1 .648.284C13.855 6.495 14 7.231 14 8c0 .769-.145 1.505-.41 2.182a.5.5 0 0 1-.93-.364C12.88 9.255 13 8.642 13 8c0-.642-.12-1.255-.34-1.818a.5.5 0 0 1 .283-.648z"/>
                </svg> 
                <a className="color-inherit pointer" href="tel:+6309801015">(630) 980-1015</a>
            </div>
        </div>,
        image: require('./assets/searching.png')
    },
    {
        header: "OEM Parts & Supplies",
        content: 'Toner, parts, developers, drums, rollers, service manuals, anything you can think of, and more!',
        buttonText: "View Parts & Supplies Inventory",
        buttonLink: '/inventory/supplies',
        image: require('./assets/webCircles.png')
    },

    {
        header: 'Logistics',
        content: "Don't have a dock? Require tracking numbers? Out of state? No problem! We arrange all shipping per your individual needs. Paired with our nationwide shipping, we're your onestop shop!",
        //buttonText: 'Contact Us',
        //buttonLink: '/contact',
        image: require('./assets/peopleTalking.png')
    },
    {
        header: "New Equipment",
        content: 'Pre-owned not for you? Contact us to get a quote on new equipment straight from the manufacturer!',
        //buttonText: "Get a Quote",
        //buttonLink: '/contact',
        image: require('./assets/creditCard.png')
    }
]

