import React, { useCallback, useEffect, useState } from "react";
import InventoryTable from '../../components/table/InventoryTable'
import Spinner from 'react-bootstrap/Spinner'
import useSWR, {mutate} from 'swr'
import fetchFile from '../../utilities/Fetcher'
import Button from 'react-bootstrap/Button'
import { INVENTORY_URL } from "../../constants";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { parse } from 'query-string'

const DownloadButton = <a href={INVENTORY_URL}><Button>Download Inventory</Button></a>

const Copiers = () => {
    // SWR is super useful for caching request data,
    // not really useful for us today, but makes this code a lil shorter
    const { data, error } = useSWR(INVENTORY_URL, fetchFile)
    const [ updatedData, setUpdatedData ] = useState()
    const location = useLocation()
    const params = parse(location.search);

    useEffect(() => {
        if (!updatedData) {
            setUpdatedData(data)
        }
    }, [data,setUpdatedData,updatedData])

    const updateTable = useCallback(() => {
        // tell all SWRs with this key to revalidate
        mutate(INVENTORY_URL)
        setUpdatedData({})
        setUpdatedData(data)
    }, [data, setUpdatedData], updatedData)

    return <>
    <Helmet>
        <title>Today's wholesale copier inventory</title>
        <meta name="description" content="Wholesale inventory of pre-owned copiers. Search for brands like Minolta - Bizhub - Copystar - Kyocera - Toshiba and More!"/>
    </Helmet>
    {!updatedData && !error ? 
        <div className="mw5 mw7-ns center tc bg-light-gray pa3 ph5-ns">
            <Spinner className="" animation="border" role="status"/>
            <div className="">Loading Wholesale Copier Inventory</div>
        </div> : error ?
            <div className="ph2 w5 ml-auto mr-auto">
                <h1 className="tc">
                    Uh oh!
                </h1>
                <p className="">
                    Looks like we couldn't properly display today's wholesale inventory.<br/> 
                    Please let us know by sending us a message.
                </p>
                <p>You can also try to download the inventory directly via the button below</p>
                {DownloadButton}
                <div className="mt4">
                    <h4>Contact Us:
                    </h4>
                    <p>
                        Ed Ciaglia
                    <br/>
                    <a href="mailto:EdC@copiersandsupplies.com" target="_top"> EdC@copiersandsupplies.com  </a> 
                    <br/>
                    <a href="tel:+6309801015">(630) 980-1015</a>
                    </p>
                </div>
            </div> :
        <div className="ph2">
            {
                updatedData[0].length !== 0 &&
                <InventoryTable updateTable={updateTable} headers={updatedData[0]} data={updatedData[1]} initialSearch={params} lastUpdated={updatedData[2]}></InventoryTable>
            }
        </div>
    }
    </>
}

export default Copiers

